/** @jsx jsx */
import { arrayOf, shape, string } from 'prop-types'
import React from 'react'
import { AutoSizer, List, WindowScroller } from 'react-virtualized'
import { jsx } from 'theme-ui'
import DocTile from './DocTile'

// DocGrid might need to display a lot of docs (>200).
// To avoid an excessive DOM size, we use react-virtualized
// to only render the docs that are visible on the screen.

const ROW_HEIGHT = 100
const MAX_COLUMN_WIDTH = 320

function DocGrid({ docs }) {
  // Initialize numColumns to an arbitrary number.
  const [numColumns, setNumColumns] = React.useState(1)

  return (
    <div sx={{ margin: -2, minHeight: ROW_HEIGHT }}>
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer
            disableHeight
            onResize={({ width }) =>
              // Recompute the number of columns when the grid resizes.
              // This function is also called on initial render.
              setNumColumns(Math.floor(width / MAX_COLUMN_WIDTH))
            }
          >
            {({ width }) => (
              <List
                tabIndex={-1}
                autoHeight
                width={width}
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                scrollTop={scrollTop}
                rowCount={Math.ceil(docs.length / numColumns)}
                rowHeight={ROW_HEIGHT}
                rowRenderer={({ key, index: rowIndex, style }) => (
                  <div
                    key={key}
                    style={style}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
                      justifyItems: 'stretch',
                      alignItems: 'stretch',
                    }}
                  >
                    {// Render each column.
                      Array.from({ length: numColumns }, (value, columnIndex) => {
                        // Calculate the doc index using row and column indices.
                        const doc = docs[rowIndex * numColumns + columnIndex]

                        // The doc index we computed might be out of range.
                        // If that's the case, render nothing.
                        if (!doc) {
                          return null
                        }

                        return (
                          <div key={doc._id} sx={{ padding: 2 }}>
                            <DocTile
                              title={doc.title}
                              onClick={event => {
                                console.log(doc._id)
                                if (event.shiftKey) {
                                  window.open('/posts/' + doc._id, '_blank')
                                } else {
                                  location.href = '/posts/' + doc._id
                                }
                              }}
                            />
                          </div>
                        )
                      })}
                  </div>
                )}
              />
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </div>
  )
}

DocGrid.propTypes = {
  docs: arrayOf(
    shape({
      title: string
    }),
  ).isRequired,
}

export default DocGrid
