/** @jsx jsx */
import { jsx } from 'theme-ui'
import Button from './Button'

function handleClick() {
  if (window.__wxjs_environment === 'miniprogram' || navigator.userAgent.indexOf('AliApp') > -1 || navigator.userAgent.toLowerCase().includes('toutiaomicroapp')) {
    location.href = 'tel:40088-45670'
  } else {
    window.open('https://ear-weee.cn/chat.html', '', 'top=' + (screen.availHeight - 663) / 2 + ',left=' + (screen.availWidth - 750) / 2 + ',width=750,height=663,scrollbars=0,status=0,menubar=0,resizable=2,location=0')
  }
}

function Customize() {
  return (
    <div sx={{ display: 'grid', gridGap: 4 }}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h2 sx={{ margin: 0, fontSize: 4, fontWeight: 'bold' }}>在线FDA业务专家</h2>
        <Button
          onClick={handleClick}
          // onClick={handleReset}
          // disabled={isEqual(values, INITIAL_VALUES)}
          // as="a"
          // href="https://ear-weee.cn/chat.html"
          sx={{
            variant: 'buttons.outline',
            paddingY: 1,
            paddingX: 2,
            fontSize: 1,
          }}
        >
          交谈
        </Button>
      </div>
    </div>
  )
}

export default Customize
