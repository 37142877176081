/** @jsx jsx */
import { jsx } from 'theme-ui'
import Link from './Link'
import OutboundLink from './OutboundLink'

function Footer() {
  return (
    <footer sx={{ paddingY: 4, borderTop: '1px solid', borderColor: 'border', fontSize: 1 }}>
      &copy; FDA.LTD 版权所有&nbsp;&nbsp;
      <Link
        as={OutboundLink}
        href="http://beian.miit.gov.cn"
        sx={{ color: '#c0c0c0' }}
      >
        京ICP备2020044077号
      </Link>
    </footer>
  )
}

export default Footer
