import Fuse from 'fuse.js'
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

function useSearch(query) {
  const data = useStaticQuery(graphql`
    query {
      allPosts {
        nodes {
          _id
          _modified
          title
          content
        }
      }
    }
  `)

  const docs = data.allPosts.nodes
  const [results, setResults] = React.useState(Object.values(docs))

  const fuse = new Fuse(Object.values(docs), {
    threshold: 0.2,
    keys: ['title', 'content'],
  })

  React.useEffect(() => {
    if (query.trim()) {
      setResults(fuse.search(query.trim()))
    } else {
      setResults(Object.values(docs))
    }
  }, [query])

  return results
}

export default useSearch
