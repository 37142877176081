/** @jsx jsx */
import { func, string } from 'prop-types'
import { jsx } from 'theme-ui'

function DocTile({ title, onClick, ...props }) {
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={event => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          onClick(event)
        }
      }}
      sx={{
        width: '100%',
        height: '100%',
        padding: 4,
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'background',
        borderRadius: 1,
        boxShadow: 1,
        outline: 0,
        '&:hover': {
          boxShadow: 2,
        },
        '&:focus': {
          boxShadow: theme => `0 0 0 3px ${theme.colors.primary}`,
        },
      }}
      {...props}
    >
      <div
        sx={{
          // flex: '1 1 auto',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
          fontWeight: '500',
        }}
      >
        {title}
      </div>
    </div>
  )
}

DocTile.propTypes = {
  title: string.isRequired,
  onClick: func.isRequired,
}

export default DocTile
