/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'

function CsAd(props) {
  const containerRef = React.useRef(null)

  React.useEffect(() => {
    const s = document.querySelector("#_cs_js")
    s && s.parentNode.removeChild(s)
    const script = document.createElement('script')
    script.src = '//ww.obmed.cn/js/cs.js?' + Math.random()
    script.id = '_cs_js'
    containerRef.current.appendChild(script)
  }, [])

  return (
    <div
      ref={containerRef}
      sx={{
        width: '100%',
        minHeight: 132,
        padding: 4,
        fontSize: 1,
        backgroundColor: 'background',
        borderRadius: 1,
        overflow: 'hidden',
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
      }}
      {...props}
    />
  )
}

export default CsAd
