/** @jsx jsx */
import { jsx } from 'theme-ui'
import Button from './Button'

function Hero() {
  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingY: [8, 9],
        paddingX: 5,
      }}
    >
      <h1
        sx={{
          marginTop: 0,
          marginBottom: 5,
          fontSize: 6,
          fontWeight: 'normal',
          lineHeight: 'tight',
          textAlign: 'center',
        }}
      >
        FDA注册/认证更简单
      </h1>

      <div
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          justifyContent: 'center',
          width: '100%',
          '& > :not(:last-child)': {
            marginRight: [0, 4],
            marginBottom: [5, 0],
          },
        }}
      >
        <Button
          as="a"
          href="/register"
        >
          开始FDA注册
        </Button>
        <Button
          as="a"
          href="/about"
          sx={{ variant: 'buttons.outline' }}
        >
          了解更多
        </Button>
      </div>
    </div>
  )
}

export default Hero
