/** @jsx jsx */
import { jsx } from 'theme-ui'
import Customize from './Customize'
import CsAd from './CsAd'

function Sidebar() {
  return (
    <div sx={{ paddingY: 5, paddingX: 4 }}>
      <Customize />
      <CsAd sx={{ marginTop: 6 }} />
    </div>
  )
}

export default Sidebar
